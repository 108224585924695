<template>
    <div class="col-lg-7 col-xl-3">
        <!-- Simple card -->
        <b-card style="border-radius: 13px;">

            <router-link to="" @click="showUpdateModal" style=" color: #303030;">
                <div>
                    <img class="card-img" :src="image" alt="Card image" style="object-fit: cover; opacity: 0.9; ">
                    <div class="box-shadow"></div>
                    <h4 style="position:absolute; top:194px; left: 30px; color: #fff; z-index: 11;">{{ time }}</h4>
                </div>

                <b-card-title>
                    <h5 class="card-title"
                        style=" font-size: 1.4em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 20px;">
                        {{ title }}</h5>
                </b-card-title>
                <b-card-text
                    style="font-size: 1.2em; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; line-clamp: 3; -webkit-box-orient: vertical; margin-bottom: 20px;">
                    <div>
                        {{ description }}
                    </div>
                </b-card-text>
            </router-link>
            <b-button class="c2play-primary" style="margin-left: 35%; margin-right: auto;">
                <b-dropdown class="card-drop" variant="white" menu-class="dropdown-menu-end" right toggle-class="p-0">
                    <template v-slot:button-content>
                        <span style="color: #fff;"><i class="mdi mdi-dots-horizontal font-size-18"></i></span>
                    </template>
                    <!-- <b-dropdown-item :href="route1 + id"
                          >{{ $t("pages.videos.actions.details") }}</b-dropdown-item
                        > -->
                    <b-dropdown-item :href="route2 + id">{{ $t("pages.videos.actions.modifier") }}</b-dropdown-item>
                    <b-dropdown-item @click="confirm(id)">{{ $t("pages.videos.actions.supprimer") }}</b-dropdown-item>
                </b-dropdown>
            </b-button>
        </b-card>

    </div>





    <b-modal v-model="updateModal" id="modal-ml" size="ml" title="Modifier Slide" title-class="font-18" hide-footer>

        <div class="card-body">
            <form>
                <div class="form-group row mb-4">
                    <label for="projectname" class="col-form-label">{{ $t('pages.slides.formulaire.titre.label') }}</label>
                    <div class="col-lg-12">
                        <input id="projectname" name="projectname" type="text" class="form-control" v-model="slide.titre"
                            :placeholder="$t('pages.slides.formulaire.titre.placeholder')" />
                    </div>
                </div>

                <div class="form-group row mb-4">
                    <label for="projectbudget" class="col-form-label">{{ $t('pages.slides.formulaire.image') }}</label>
                    <div class="col-lg-12">
                        <input id="projectbudget" name="projectbudget" type="file" @change="imageCategorie($event)"
                            class="form-control" accept=".png, .jpeg, .jpg" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="projectdesc" class="col-form-label">{{ $t('pages.slides.formulaire.description.label')
                    }}</label>
                    <div class="col-lg-12">
                        <textarea id="projectdesc" class="form-control" rows="3" v-model="slide.description"
                            :placeholder="$t('pages.slides.formulaire.description.placeholder')"></textarea>
                    </div>
                </div>
            </form>
            <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
            <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
            <div class="row justify-content-end">
                <div class="col-lg-10" style="margin-top: 10px;">
                    <button type="submit" class="btn c2play-primary" style="position: relative; left: 30%;"
                        @click="updateSlide">
                        {{ $t('updateButton') }}
                    </button>
                </div>
            </div>
        </div>
    </b-modal>
</template>
  
<script>

import Swal from 'sweetalert2';
import { Api } from '../../helpers';
import { Erreur } from '../../helpers/error';
export default {
    data() {
        return {
            updateModal: false,
            slide: {
                titre: "",
                image: "",
                description: "",
            }
        }
    },
    props: {
        id: {
            default: ""
        },
        image: {
            default: ""
        },
        title: {
            default: ""
        },
        time: {
            default: ""
        },
        description: {
            default: ""
        },
        route1: {
            default: ""
        },
        route2: {
            default: ""
        },
        route3: {
            default: ""
        }
    },
    mounted() {
        this.slide.titre = this.title
        this.slide.image = this.image
        this.slide.description = this.description
    },
    methods: {
        showUpdateModal() {
            this.updateModal = true
        },
        confirm(id) {
            Swal.fire({
                title: "Etes vous sùre?",
                // text: "You won't be able to delete this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                cancelButtonText: "Annuler",
                confirmButtonText: "Supprimer",
            }).then((result) => {
                if (result.value) {
                    // alert(id)
                    this.deleteProgramme(id)
                    Swal.fire("Deleted!", "Event has been deleted.", "success");
                }
            });
        },
        deleteProgramme(programmeId) {
            let token = localStorage.getItem('jwtToken')
            console.log(token)

            Api.delete("/streamvod/rest/programme/delete/" + programmeId)
                .then((response) => {
                    this.channels = response.data.content
                    console.log(this.channels)
                    location.reload()
                }).catch((error) => {
                    console.log(error)
                    Erreur.gestionErreur(error.message)
                })
        },
        imageCategorie(event) {
            this.imageSlide = event.target.files[0]
            console.log(this.imageSlide)
        },
        updateSlide() {
            this.process = true
            this.modalOk = false

            Api.putFormData(`/streamvod/rest/slider/update-slider/${this.id}`, {
                file: this.imageSlide,
                description: this.slide.description,
                titre: this.slide.titre
            })

                .then((response) => {
                    this.process = false
                    Swal.fire("Erreur!", "Catégorie ajoutée", "success");
                    console.log(response);
                    location.reload()
                }).catch((error) => {
                    this.process = false
                    console.log(error)
                    Erreur.gestionErreur(error.message)
                })
        },
    }

}
</script>
  
<style>
.card-img {
    object-fit: cover;
    height: 200px;
    width: 100%;
    border-radius: 9px;
    margin-bottom: 20px;
}

.box-shadow {

    top: 20px;
    height: 200px;
    width: 86%;
    position: absolute;
    border-radius: 10px;
    z-index: 1;
    -webkit-box-shadow: inset 0px -185px 112px -95px rgba(4, 87, 17, 0.57);
    -moz-box-shadow: inset 0px -185px 112px -95px rgba(4, 87, 17, 0.57);
    box-shadow: inset 0px -185px 112px -95px rgba (4, 87, 17, 0.57);
}
</style>