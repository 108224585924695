<script>
var moment = require('moment');
moment.locale('fr');
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from '../../../helpers';
import { Erreur } from "../../../helpers/error";
import slideCardImageVue from '../../../components/widgets/slideCardImage.vue';
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
    slideCardImageVue
  },

  data() {
    return {
      process: false,
      modalOk: false,
      slideInfos: {
        titre: "",
        description: "",
      },
      slides: [],
      updateModal: false,
      moment: moment,
      contenu: "video",
      videos: [],
      programmes: [],
      title: "Liste des Slides",
      items: [
        {
          text: "Outils",
          // href: "/projects/slide",
        },
        {
          text: "Slide",
          active: true,
        },
      ],
    };
  },
  methods: {


    showModal() {
      this.modalOk = true
    },


    imageCategorie(event) {
      this.imageSlide = event.target.files[0]
    },


    createSlide() {
      this.process = true
      this.modalOk = false
      Api.postFormData("/streamvod/rest/slider/create-slider", {
        file: this.imageSlide,
        description: this.slideInfos.description,
        titre: this.slideInfos.titre
      })
        .then(() => {
          this.process = false
          Swal.fire("Succes!", "Slide ajoutée", "success");
          location.reload()
        }).catch((error) => {
          this.process = false
          Erreur.gestionErreur(error.message)
        })
    },
    showOneSlide() {
      this.updateModal = true
    }
  },
  mounted() {

    Api.get("/streamvod/rest/slider/all")
      .then((res) => {
        this.slides = res.data.content
      }).catch((error) => {
        this.process = false
        Erreur.gestionErreur(error.message)
      })

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('pages.slides.title')" :items="items" />

    <div class="row justify-content-end">
      <div class="col-lg-10" style="margin-top: 10px;">
        <button type="submit" class="btn c2play-primary" style="position: relative; left: 90%;" @click="showModal">
          {{ $t('addButton') }}
        </button>
      </div>
    </div>

    <div class="row">
      <slideCardImageVue v-for="slide in slides" :key="slide.id" :title="slide.titre" :id="slide.id" :description="slide.description"
        :image="slide.images.url"
      ></slideCardImageVue>
    </div>
    <b-modal v-model="modalOk" id="modal-ml" size="ml" :title="$t('pages.slides.formulaire.title')" title-class="font-18"
      hide-footer>

      <div class="card-body">
        <form>
          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label">{{ $t('pages.slides.formulaire.titre.label') }}</label>
            <div class="col-lg-12">
              <input id="projectname" name="projectname" type="text" class="form-control" v-model="slideInfos.titre"
                :placeholder="$t('pages.slides.formulaire.titre.placeholder')" />
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label">{{ $t('pages.slides.formulaire.image') }}</label>
            <div class="col-lg-12">
              <input id="projectbudget" name="projectbudget" type="file" @change="imageCategorie($event)"
                class="form-control" accept=".png, .jpeg, .jpg" />
            </div>
          </div>

          <div class="form-group row">
            <label for="projectdesc" class="col-form-label">{{ $t('pages.slides.formulaire.description.label') }}</label>
            <div class="col-lg-12">
              <textarea id="projectdesc" class="form-control" rows="3" v-model="slideInfos.description"
                :placeholder="$t('pages.slides.formulaire.description.placeholder')"></textarea>
            </div>
          </div>
        </form>
        <!-- <div class="row mb-4">
                <label class="col-form-label col-lg-2">Vidéo</label>
                <div class="col-lg-10">
                  <DropZone @drop.prevent="drop" @change="selectedFile" />
                  <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
                </div>
              </div> -->
        <!-- <h4>{{ tags }}</h4>
              <h4>{{ categories }}</h4> -->
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px;">
            <button type="submit" class="btn c2play-primary" style="position: relative; left: 30%;" @click="createSlide">
              {{ $t('updateButton') }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <loaderProcess v-if="process == true"></loaderProcess>
    <!-- end row -->
  </Layout>
</template>

<style></style>